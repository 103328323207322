<template>
    <div class="unit-add-edit">
        <!--顶部面包靴-->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/volunteer/volunteerGroups' }" class="fs20">志愿团体</el-breadcrumb-item>
            <el-breadcrumb-item class="fs20">详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!--表单-->
        <div class="mt30 infobox">
            <div class="title">团体基础信息</div>
            <el-form :model="ruleForm" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                <el-form-item label="志愿团体名称：">
                    <span>{{ ruleForm.groupName || '--' }}</span>
                </el-form-item>
                <el-form-item label="志愿团体ID：">
                    <span>{{ ruleForm.groupId || '--' }}</span>
                </el-form-item>
                <el-form-item label="所在区域：">
                    <span> {{ ruleForm.regional || '--' }}</span>
                </el-form-item>
                <el-form-item label="成立日期：">
                    <span> {{ ruleForm.createTime || '--' }}</span>
                </el-form-item>
                <el-form-item label="详细地址：">
                    <span> {{ ruleForm.fullAddress || '--' }}</span>
                </el-form-item>
                <el-form-item label="头像：">
                    <span>{{ ruleForm.avatar || '--' }}</span>
                </el-form-item>
                <div class="title">负责人/联系人信息</div>
                <el-form-item label="姓名：">
                    <span>{{ ruleForm.contacts || '--' }}</span>
                </el-form-item>
                <el-form-item label="手机号：">
                    <span>{{ ruleForm.contactPhone || '--' }}</span>
                </el-form-item>
                <div class="title">账号信息</div>
                <el-form-item label="账号：">
                    <span>{{ ruleForm.accountNumber || '--' }}</span>
                </el-form-item>
                <el-form-item label="所属部门：">
                    <span>{{ ruleForm.accountNumber || '--' }}</span>
                </el-form-item>
                <el-form-item label="所属角色：">
                    <span>{{ ruleForm.accountNumber || '--' }}</span>
                </el-form-item>
                <el-form-item>
                    <el-button @click="$router.go(-1)">返 回</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { backDepartmentAndRole, backRegionList } from '@/api/volunteer'
export default {
    data() {
        return {
            ruleForm: {},
            departmentName: '',
            roleName: ''
        }
    },
    mounted() {
        this.ruleForm = JSON.parse(this.$route.query.info)
        this.backRegionList()
        this.backDepartmentAndRole()
    },
    methods: {
        async backRegionList() {
            const res = await backRegionList()
            this.regionList = res.data
        },
        async backDepartmentAndRole() {
            const res = await backDepartmentAndRole()
            this.departmentName = res.data.volunteerDepartment.departmentName
            this.roleName = res.data.role.roleName
        }
    }
}
</script>

<style lang="scss" scoped>
.unit-add-edit {
    background-color: #ffffff;
    padding: 20px;
    .infobox {
        margin-left: 30px;
    }
    .fs20 {
        font-size: 14px;
    }
    .mt30 {
        margin-top: 30px;
    }
    .title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
    }
}
</style>
